import { useEffect } from 'react';
import { ModalProps } from '../../components/Modal/types';

const DEFAULT_FULL_SCREEN_BREAKPOINT = 'md' satisfies ModalProps['fullScreenBreakpoint'];

export const useComputedFullScreenBreakpoint = (
    fullScreenBreakpoint?: ModalProps['fullScreenBreakpoint'],
    mobileFullScreen?: ModalProps['mobileFullScreen']
): NonNullable<ModalProps['fullScreenBreakpoint']> => {
    useEffect(() => {
        if (mobileFullScreen !== undefined) {
            // eslint-disable-next-line no-console
            console.warn(
                'Modal: The mobileFullScreen prop is deprecated, please use the fullScreenBreakpoint prop instead'
            );
        }
    }, [mobileFullScreen]);

    if (fullScreenBreakpoint !== undefined) {
        return fullScreenBreakpoint;
    }

    if (mobileFullScreen !== undefined) {
        return mobileFullScreen ? DEFAULT_FULL_SCREEN_BREAKPOINT : 'none';
    }

    return DEFAULT_FULL_SCREEN_BREAKPOINT;
};

import { theme } from '@fiverr-private/theme';

export const OPACITY_VALUES = {
    high: '0.8',
    low: '0.55',
};

export const COLORS = {
    white: theme.colors.white,
    grey: theme.colors.grey_1200,
};

import { createContext, useContext } from 'react';

interface ModalContextType {
    isOpen: boolean;
    close: () => void;
    centered?: boolean;
    open: () => void;
    fullScreenBreakpoint: 'sm' | 'md' | 'none';
    isDark: boolean;
}

export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalContextProvider');
    }
    return context;
};

import { stats as unprefixedStats } from '@fiverr-private/obs';
import { getIsClient } from '../../utils/getIsClient';
import { getClientDevice } from '../../utils/getClientDevice';
import { GrafanaEventName, GrafanaPlatform } from './grafana.types';

type MetricValue = NonNullable<Parameters<typeof unprefixedStats.count>[2]>;

const isClient = getIsClient();

export interface CreateProps {
    grafanaPrefix: string;
}

export const createGrafanaReporter = <TEventName extends string = GrafanaEventName>({ grafanaPrefix }: CreateProps) => {
    type ExtendedGrafanaEventName = TEventName | GrafanaEventName;

    const platform: GrafanaPlatform = isClient ? getClientDevice() : 'server';
    let stats = unprefixedStats.prefix(grafanaPrefix);

    const parseMetric = (metric: ExtendedGrafanaEventName): string => metric.replace('%{platform}', platform);

    const count = (metric: ExtendedGrafanaEventName, value?: MetricValue) => {
        stats.count(parseMetric(metric), value);
    };

    const extend = <TEventName extends string>({ grafanaPrefix: newPrefix }: CreateProps) => {
        stats = unprefixedStats.prefix(newPrefix);

        return instance as ReturnType<typeof createGrafanaReporter<TEventName>>;
    };

    const instance = {
        count,
        extend,
    } as const;

    return instance;
};

export type GrafanaReporter = ReturnType<typeof createGrafanaReporter<GrafanaEventName>>;

export const globalGrafanaReporter = createGrafanaReporter({ grafanaPrefix: 'packages.identification_core' });

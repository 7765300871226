
import { hydrateCSRTemplate } from "@fiverr-private/layout_lib/CSRTemplate";

import Identification from '/home/circleci/app/packages/layout-identification-component/src/entries/IdentificationMarketplaceModal';

const element = typeof document !== 'undefined' && document.querySelector('#Identification');
if (element) {
    const props = window.initialData['Identification'];

    hydrateCSRTemplate(Identification, props, element, 'Identification', 'IdentificationMarketplaceModal');
}
export default Identification;

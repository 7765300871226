import { pickBy, startCase } from 'lodash';
import React, { lazy, Suspense, useMemo } from 'react';
import { Modal, ModalContent } from '@fiverr-private/overlay';
import { useIsClient } from '@fiverr-private/hooks';
import { SkeletonLoading } from '../SkeletonLoading';
import { TriggerType } from '../../shared/constants/triggerType';
import { InitialValues, ModalOptions } from '../../shared/constants';
import { useBreakpointMd } from '../../hooks/useBreakpoint';
import { useSkipQuestionnaireQueryParam } from '../../hooks/useSkipQuestionnaireQueryParam';
import {
    MIXPANEL_BLOCKED_ACTION_MAP,
    MIXPANEL_SIGNING_MODE_TYPE_MAP,
    MIXPANEL_TRIGGER_TYPE_MAP,
} from '../../events/mixpanel/mixpanel.constants';
import { MixpanelEventsContext } from '../../events/mixpanel/mixpanel.types';
import { BigQueryEventsContext } from '../../events/bigQuery/bigQuery.types';
import { useSetupExternalApi } from './hooks/useSetupExternalApi';
import { useSetupModal } from './hooks/useSetupModal';

const LazyGoogleOneTap = lazy(() => import(/* webpackChunkName: "LazyGoogleOneTap" */ '../GoogleOneTap'));

interface IdentificationModalWrapperProps {
    googleClientId: string;
    inLynxesIdentificationMarketplaceRollout?: boolean;
    inDolphinsIdentificationBusinessRollout?: boolean;
    autoShowSignUpEnabled?: boolean;
    closeOnOverlayClick?: boolean;
    renderLazyComponent: (props: {
        isSocialFlow: ModalOptions['isSocialFlow'];
        skipActivation: ModalOptions['skipActivation'];
        stepId: ModalOptions['stepId'];
        isModal: boolean;
        isModalOpen: boolean;
        closeModal: () => void;
        mode: ModalOptions['mode'];
        variant: ModalOptions['variant'];
        source?: string;
        redirectUrl?: string;
        email: InitialValues['email'];
        username: InitialValues['username'];
        isSticky: ModalOptions['isSticky'];
        skipQuestionnaire: ModalOptions['skipQuestionnaire'];
        blockedActionType: ModalOptions['blockedActionType'];
        mixpanelEnrichment: MixpanelEventsContext;
        bigQueryEnrichment: BigQueryEventsContext;
        afterSigningSuccess: ModalOptions['afterSigningSuccess'];
    }) => React.ReactNode;
}

export const IdentificationModalWrapper = ({
    googleClientId,
    inLynxesIdentificationMarketplaceRollout,
    inDolphinsIdentificationBusinessRollout,
    autoShowSignUpEnabled,
    closeOnOverlayClick,
    renderLazyComponent,
}: IdentificationModalWrapperProps) => {
    const isClient = useIsClient();
    const { showLazyComponent, modalOptions, openModal, closeModal, isModalOpen, autoShow, biEnrichmentParams } =
        useSetupModal({
            inLynxesIdentificationMarketplaceRollout,
            inDolphinsIdentificationBusinessRollout,
            autoShowSignUpEnabled,
        });

    useSetupExternalApi();

    const {
        triggerId,
        triggerType,
        triggerCopy,
        redirectUrl,
        initialValues = {},
        triggerComments,
        triggerPlacement,
        blockedActionType,
        mode,
        variant,
        skipQuestionnaire: skipQuestionnaireProp,
    } = modalOptions;

    const isMdScreen = useBreakpointMd();
    const skipQuestionnaireQueryParam = useSkipQuestionnaireQueryParam();
    const skipQuestionnaire = skipQuestionnaireProp || skipQuestionnaireQueryParam;
    const source = modalOptions.source || biEnrichmentParams.source;

    const computedTriggerType: TriggerType | undefined = autoShow ? 'system_trigger' : triggerType;
    const formattedTriggerId = startCase(triggerId);

    const { mixpanelEnrichment, bigQueryEnrichment } = useMemo(() => {
        const isTriggerMetadataExist = triggerCopy || triggerPlacement || triggerComments;
        const mixpanelEnrichment = pickBy<MixpanelEventsContext>(
            {
                ...biEnrichmentParams,
                redirectUrl,
                ...(mode && { mode: MIXPANEL_SIGNING_MODE_TYPE_MAP[mode] }),
                ...(blockedActionType && { blockedActionType: MIXPANEL_BLOCKED_ACTION_MAP[blockedActionType] }),
                ...(computedTriggerType && { triggerType: MIXPANEL_TRIGGER_TYPE_MAP[computedTriggerType] }),
                triggerId: formattedTriggerId,
                triggerCopy: startCase(triggerCopy),
                triggerPlacement: startCase(triggerPlacement),
                triggerComments: startCase(triggerComments),
            },
            (value) => value !== undefined
        );

        const bigQueryEnrichment = pickBy<BigQueryEventsContext>(
            {
                ...biEnrichmentParams,
                mode,
                redirectUrl,
                reason: computedTriggerType,
                triggerId: formattedTriggerId,
                ...(source ? { source } : {}),
                ...(isTriggerMetadataExist && {
                    triggerMetadata: JSON.stringify({
                        'Trigger Copy': triggerCopy,
                        'Trigger Placement': triggerPlacement,
                        'Trigger Comments': triggerComments,
                    }),
                }),
            },
            (value) => value !== undefined
        );

        return {
            mixpanelEnrichment,
            bigQueryEnrichment,
        };
    }, [
        mode,
        redirectUrl,
        blockedActionType,
        computedTriggerType,
        triggerComments,
        triggerCopy,
        formattedTriggerId,
        triggerPlacement,
        biEnrichmentParams,
        source,
    ]);

    return (
        <>
            <Modal onOpen={openModal} onClose={closeModal} isOpen={isModalOpen} fullScreenBreakpoint="sm">
                {showLazyComponent && (
                    <ModalContent size={isMdScreen ? 'xl' : 'sm'} closeOnOverlayClick={closeOnOverlayClick}>
                        <Suspense fallback={<SkeletonLoading loading height="645px" maxHeight="100%" />}>
                            {renderLazyComponent({
                                isSocialFlow: modalOptions.isSocialFlow,
                                skipActivation: modalOptions.skipActivation,
                                stepId: modalOptions.stepId,
                                isModal: true,
                                isModalOpen,
                                closeModal,
                                mode,
                                variant,
                                source,
                                redirectUrl,
                                email: initialValues.email,
                                username: initialValues.username,
                                isSticky: modalOptions.isSticky,
                                skipQuestionnaire,
                                blockedActionType: modalOptions.blockedActionType,
                                mixpanelEnrichment,
                                bigQueryEnrichment,
                                afterSigningSuccess: modalOptions.afterSigningSuccess,
                            })}
                        </Suspense>
                    </ModalContent>
                )}
            </Modal>
            {isClient && (
                <Suspense fallback={null}>
                    <LazyGoogleOneTap
                        googleClientId={googleClientId}
                        afterSigningSuccess={modalOptions.afterSigningSuccess}
                        inLynxesIdentificationMarketplaceRollout={inLynxesIdentificationMarketplaceRollout}
                    />
                </Suspense>
            )}
        </>
    );
};

import React from 'react';
import { useLockBodyScroll } from '@fiverr-private/hooks';
import { Container } from '@fiverr-private/layout_components';
import { hex2rgb } from '@fiverr-private/theme';
import { OverlayProps } from './types';
import { OPACITY_VALUES, COLORS } from './constants';

const Overlay = ({
  children,
  opacity = 'low',
  lockBodyScroll = true,
  background = 'grey',
  position = 'fixed',
  zIndex = 'overlay',
  className,
  onClick,
  dataTestId,
  style,
  ...rest
}: OverlayProps) => {
  useLockBodyScroll(lockBodyScroll);
  const backgroundColor = `rgba(${hex2rgb(COLORS[background])}, ${OPACITY_VALUES[opacity]})`;

  return (
    <Container
      width="100%"
      height="100%"
      top={0}
      bottom={0}
      left={0}
      right={0}
      className={className}
      style={style}
      zIndex={zIndex}
      background={backgroundColor}
      position={position}
      onClick={onClick}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </Container>
  );
};

Overlay.displayName = 'Overlay';

export default Overlay;

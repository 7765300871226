import Cookies from 'universal-cookie';
import { MONTH_IN_MS, sessionStorage } from '@fiverr-private/futile';
import { logger } from '@fiverr-private/obs';
import {
    COOKIE_PATH,
    CURRENT_SESSION_URL_KEY,
    DEFAULT_PAGE_VIEWS,
    DOMAIN_NAME,
    PAGE_VIEW_COOKIE_NAME,
} from './constants';

/**
 * This is a copypaste from the old page view tracker from user_session.
 */
class LegacyPageViewTracker {
    cookies: Cookies;

    constructor() {
        this.cookies = new Cookies();
    }

    track() {
        let pageViews = this.pageViews;

        if (this.isRefresh) {
            return pageViews;
        }

        pageViews++;

        this.setPageViewsCookie(pageViews);
        this.setCurrentSessionUrl();

        return pageViews;
    }

    get pageViews() {
        const pageViewsCookie = this.cookies.get(PAGE_VIEW_COOKIE_NAME);

        return Number(pageViewsCookie) || DEFAULT_PAGE_VIEWS;
    }

    get isRefresh() {
        const currentSessionUrl = sessionStorage.get(CURRENT_SESSION_URL_KEY);

        return currentSessionUrl === this.currentUrl;
    }

    setPageViewsCookie(pageViews) {
        const options = {
            expires: new Date(Date.now() + MONTH_IN_MS),
            path: COOKIE_PATH,
        };

        if (this.currentUrl.includes(DOMAIN_NAME)) {
            (options as any).domain = DOMAIN_NAME;
        }

        this.cookies.set(PAGE_VIEW_COOKIE_NAME, pageViews, options);
    }

    setCurrentSessionUrl() {
        sessionStorage.set(CURRENT_SESSION_URL_KEY, this.currentUrl).catch((error) =>
            logger.error(error, {
                description: 'Error while setting current session url',
            })
        );
    }

    get currentUrl() {
        return document.location.href;
    }
}

export const legacyPageViewTracker = new LegacyPageViewTracker();

import { triggerService } from '../services/triggerService';
import { BiEnrichmentParams } from '../../shared/constants/biEnrichmentParams';
import { ModalOptions } from '../../shared/constants/modalOptions';
import { SigningModalTypeValues } from '../../shared/constants/signingModalType';

/**
 * This function is intended to be used only by layout.
 */
export const registerTrigger = (
    callback: (modalOptions?: ModalOptions, BiEnrichmentParams?: BiEnrichmentParams) => void
) => {
    triggerService.registerTriggerApi(SigningModalTypeValues.identification, callback);
};

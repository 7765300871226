import { SigningModeType } from '../../shared/constants/signingModeType';
import { BlockedActionType } from '../../shared/constants/blockedActionType';
import { TriggerType } from '../../shared/constants/triggerType';
import { CommonStepId, FunnelType } from '../../shared/constants';

export type MixpanelStepType = ValueOf<typeof MIXPANEL_STEP_TYPE_MAP>;
export type MixpanelProviderType = ValueOf<typeof MIXPANEL_PROVIDER_TYPE_MAP>;
export type MixpanelTriggerType = ValueOf<typeof MIXPANEL_TRIGGER_TYPE_MAP>;
export type MixpanelBlockedActionType = ValueOf<typeof MIXPANEL_BLOCKED_ACTION_MAP>;
export type MixpanelSigningModeType = ValueOf<typeof MIXPANEL_SIGNING_MODE_TYPE_MAP>;
export type MixpanelSigningFlowType = 'Sign In' | 'Sign Up' | 'Social Sign Up' | 'Social';
export type Provider = 'standard' | 'google' | 'facebook' | 'apple';
export type IdentificationAllocationPoint = 'Standard' | 'Google One Tap';

export interface MixpanelEventProperties {
    mode: MixpanelSigningModeType;
    step: MixpanelStepType;
    triggerType: MixpanelTriggerType;
    blockedActionType: MixpanelBlockedActionType;
    flow: MixpanelSigningFlowType;
    pageName: string;
    provider: MixpanelProviderType;
    errorType: string;
    triggerId: string;
    triggerCopy: string;
    triggerPlacement: string;
    triggerComments: string;
    redirectUrl: string;
    experience: 'Identification';
    funnel: FunnelType;
    emailDomain: string;
    viewType: string;
    sourceComponent: string;
    identificationMigrationExperiment: number;
    identificationBusinessMigrationExperiment: number;
    package: string;
    allocationPoint: 'Standard' | 'Google One Tap';
    googleOneTap: boolean;
}

export const MIXPANEL_PROPERTIES_MAP = {
    funnel: 'Funnel',
    mode: 'Intention',
    step: 'Identification Step',
    flow: 'Flow',
    errorType: 'Error Type',
    emailDomain: 'Email Domain',
    redirectUrl: 'Redirect Url',
    pageName: 'Page Name',
    provider: 'Provider',
    triggerId: 'Trigger Id',
    experience: 'Experience',
    triggerType: 'Trigger',
    triggerCopy: 'Trigger Copy',
    triggerComments: 'Trigger Comments',
    triggerPlacement: 'Trigger Placement',
    blockedActionType: 'Blocked Action',
    viewType: 'View Type',
    sourceComponent: 'Source Component',
    identificationMigrationExperiment: 'Experiment 4728',
    identificationBusinessMigrationExperiment: 'Experiment 4665',
    package: 'Package',
    allocationPoint: 'Allocation Point',
    googleOneTap: 'Google One Tap',
} as const satisfies Record<keyof MixpanelEventProperties, string>;

export const MIXPANEL_STEP_TYPE_MAP = {
    initial: 'Initial',
    email_identification: 'Email Identification',
    profile_setup: 'Profile Setup',
    reset_password: 'Reset Password',
    code_activation: 'Code Activation',
    change_email: 'Change Email',
    social_fallback: 'Social Fallback',
} as const satisfies Record<CommonStepId, string>;

export const MIXPANEL_TRIGGER_TYPE_MAP = {
    user_trigger: 'User Trigger',
    system_trigger: 'System Trigger',
    blocked_action: 'Blocked Action',
} as const satisfies Record<TriggerType, string>;

export const MIXPANEL_BLOCKED_ACTION_MAP = {
    contact_seller: 'Contact Seller',
    continue_to_checkout: 'Continue To Checkout',
    save_to_list: 'Save To List',
    post_a_brief: 'Post a Brief',
    become_a_seller: 'Become a Seller',
    new_brief_and_match: 'New Brief And Match',
} as const satisfies Record<BlockedActionType, string>;

export const MIXPANEL_SIGNING_MODE_TYPE_MAP = {
    sign_in: 'Sign In',
    sign_up: 'Sign Up',
} as const satisfies Record<SigningModeType, string>;

export const MIXPANEL_PROVIDER_TYPE_MAP = {
    standard: 'Standard',
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
} as const satisfies Record<Provider, string>;

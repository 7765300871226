import { useEffect } from 'react';

interface UseExitOnEscapeProps {
    enabled: boolean;
    close: () => void;
    isOpen: boolean;
}

/**
 * This hook will invoke `close` when pressing escape when enabled and drawer is open
 *
 * @param {boolean} enabled Flag that indicates if should exit on escape
 * @param {() => void} close Close callback to invoke
 * @param {boolean} isOpen Flag that indicates if the drawer is open
 */
export const useExitOnEscape = ({ enabled, close, isOpen }: UseExitOnEscapeProps) => {
    useEffect(() => {
        const onKeyPress = ({ key }: KeyboardEvent) => {
            if (key !== 'Escape') {
                return;
            }

            if (isOpen) {
                close();
            }
        };

        if (enabled) {
            document.body.addEventListener('keydown', onKeyPress);

            return () => {
                document.body.removeEventListener('keydown', onKeyPress);
            };
        }
    }, [enabled, close, isOpen]);
};

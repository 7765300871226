import { useEffect } from 'react';
import { bigQuery } from '@fiverr-private/obs';
import { triggerSignIn, triggerSignUp } from '../../../../triggers/api';
import { type ExternalTriggerParams } from '../../../../triggers/api/types';
import { globalBigQueryReporter } from '../../../../events/bigQuery/bigQuery.reporter';
import { globalMixpanelReporter } from '../../../../events/mixpanel/mixpanel.reporter';
import {
    CLASSNAME_BIGQUERY_MAP,
    CLASSNAME_MIXPANEL_MAP,
    CLASSNAME_TRIGGER_MAP,
    LEGACY_CLASSNAME_SELECTORS,
    LegacyClassnameSelector,
} from './useSetupExternalApi.constants';

const addTriggerEventListener = (selector: LegacyClassnameSelector) => {
    const elements = document.querySelectorAll(selector);

    const onClick = (event: Event) => {
        const { target } = event;
        if (!(target instanceof HTMLElement)) {
            return;
        }

        const {
            sourceComponent,
            eventType: customBigQueryEvent,
            triggerId,
            triggerCopy,
            triggerPlacement,
        } = target.dataset;

        event.preventDefault();

        globalMixpanelReporter.send(CLASSNAME_MIXPANEL_MAP[selector], {
            sourceComponent,
            viewType: 'Pop-up',
        });

        globalBigQueryReporter.send(CLASSNAME_BIGQUERY_MAP[selector], {
            group: 'signin flow',
        });

        if (customBigQueryEvent) {
            bigQuery.send({
                type: customBigQueryEvent,
                sourceComponent,
            });
        }

        CLASSNAME_TRIGGER_MAP[selector]({
            modalOptions: {
                triggerId,
                triggerCopy,
                triggerPlacement,
            },
        });
    };

    elements.forEach((element) => {
        element.addEventListener('click', onClick);
    });
};

export const useSetupExternalApi = () => {
    useEffect(() => {
        const onLoad = () => {
            window.openSignInModal = (
                modalOptions: ExternalTriggerParams['modalOptions'],
                biEnrichmentParams: ExternalTriggerParams['biEnrichmentParams']
            ) =>
                triggerSignIn({
                    modalOptions,
                    biEnrichmentParams,
                });

            window.openSignUpModal = (
                modalOptions: ExternalTriggerParams['modalOptions'],
                biEnrichmentParams: ExternalTriggerParams['biEnrichmentParams']
            ) =>
                triggerSignUp({
                    modalOptions,
                    biEnrichmentParams,
                });

            LEGACY_CLASSNAME_SELECTORS.forEach((selector) => addTriggerEventListener(selector));
        };

        window.addEventListener('load', onLoad);
    }, []);
};

import { triggerSignIn, triggerSignUp } from '../../../../triggers/api';
import { type ExternalTriggerParams } from '../../../../triggers/api/types';

type LegacyWindowMethod = (
    modalOptions: ExternalTriggerParams['modalOptions'],
    biEnrichmentParams: ExternalTriggerParams['biEnrichmentParams']
) => Promise<void>;
declare global {
    interface Window {
        openSignInModal?: LegacyWindowMethod;
        openSignUpModal?: LegacyWindowMethod;
    }
}

export const LEGACY_CLASSNAME_SELECTORS = ['.js-open-popup-login', '.js-open-popup-join'] as const;

export type LegacyClassnameSelector = (typeof LEGACY_CLASSNAME_SELECTORS)[number];

export const CLASSNAME_MIXPANEL_MAP = {
    '.js-open-popup-login': 'Clicked login',
    '.js-open-popup-join': 'Clicked join',
} as const satisfies Record<LegacyClassnameSelector, string>;

export const CLASSNAME_BIGQUERY_MAP = {
    '.js-open-popup-login': 'login_click',
    '.js-open-popup-join': 'signup_click',
} as const satisfies Record<LegacyClassnameSelector, string>;

export const CLASSNAME_TRIGGER_MAP = {
    '.js-open-popup-login': triggerSignIn,
    '.js-open-popup-join': triggerSignUp,
} as const satisfies Record<LegacyClassnameSelector, (params: ExternalTriggerParams) => void>;

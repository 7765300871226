import { sessionStorage } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import { AUTO_SHOWN_KEY, PAGE_VIEW_THRESHOLD } from './constants';
import { legacyPageViewTracker } from './tracker';

interface IsAutoShowEligibleProps {
    autoShowSignUpEnabled: boolean;
    pageViews: number;
}

export const handleAutoShowSignUp = ({ autoShowSignUpEnabled = true }) => {
    const pageViews = legacyPageViewTracker.track();

    return isAutoShowEligible({
        autoShowSignUpEnabled,
        pageViews,
    });
};

const isAutoShowEligible = ({ autoShowSignUpEnabled, pageViews }: IsAutoShowEligibleProps) => {
    const { userId } = getContext();
    const reachedPageViewThreshold = pageViews === PAGE_VIEW_THRESHOLD;
    const isShown = sessionStorage.get(AUTO_SHOWN_KEY);

    return !userId && autoShowSignUpEnabled && reachedPageViewThreshold && !isShown;
};

export const setAutoShown = () => {
    sessionStorage.set(AUTO_SHOWN_KEY, true).catch((error) =>
        logger.warn(error, {
            description: 'Failed setting auto shown sign up key',
        })
    );
};

import { clientDangerouslyAllocate, EXPERIMENT_TYPE } from '@fiverr-private/experiments';
import { Experiments } from '../../shared/constants';
import { IdentificationAllocationPoint, globalMixpanelReporter } from '../../events/mixpanel';

/**
 * Marketplace Identification allocation
 */
export const allocateToIdentificationTest = (allocationPoint: IdentificationAllocationPoint) => {
    clientDangerouslyAllocate({
        experimentId: Experiments.LYNXES_IDENTIFICATION_MARKETPLACE_FULL_MIGRATION,
        experimentType: 'guest',
        group: 2,
    });

    globalMixpanelReporter.enrichContext({ identificationMigrationExperiment: 2 }, { preserve: true });
    globalMixpanelReporter.send('Identification - Allocated', { allocationPoint });
};

/**
 * Business Identification allocation
 */
export const allocateToIdentificationBusinessTest = (allocationPoint: IdentificationAllocationPoint) => {
    clientDangerouslyAllocate({
        experimentId: Experiments.DOLPHINS_IDENTIFICATION_BUSINESS,
        experimentType: EXPERIMENT_TYPE.GUEST,
        group: 2,
    });

    globalMixpanelReporter.enrichContext({ identificationBusinessMigrationExperiment: 2 }, { preserve: true });
    globalMixpanelReporter.send('Identification - Allocated', { allocationPoint });
};

import deepSet from 'lodash/set';
import { bigQuery } from '@fiverr-private/obs';
import { AdditionalPropsMap, BigQueryEventName, BigQueryEventsContext } from './bigQuery.types';
import { BIGQUERY_EMBEDDED_PROPERTIES, BIGQUERY_PROPERTIES_MAP } from './bigQuery.constants';

export const mapProps = <TEventProperties>({
    props,
    propertiesMap,
}: {
    props: Partial<TEventProperties>;
    propertiesMap: Record<keyof TEventProperties, string>;
}) => {
    const result = {};
    Object.entries(props)
        .filter(([_, value]) => value)
        .forEach(([key, value]) => {
            const path = propertiesMap[key as keyof typeof propertiesMap] || key;
            deepSet(result, path, value);
        });
    return result;
};

export type BigQueryReporter = ReturnType<typeof createBigQueryReporter>;

export interface EventProps {}

export interface CreateProps<TEventName extends string, TEventsContext extends EventProps> {
    bigQueryEmbeddedProps?: Partial<Record<TEventName, Partial<TEventsContext>>>;
    bigQueryPropertiesMap?: Partial<Record<keyof TEventsContext, string>>;
}

export interface EnrichContextProps {
    resetState?: boolean;
    preserve?: boolean;
}

export const createBigQueryReporter = <
    TEventName extends string = BigQueryEventName,
    TEventsContext extends EventProps = BigQueryEventsContext,
    TAdditionalPropsMap = AdditionalPropsMap
>({ bigQueryEmbeddedProps, bigQueryPropertiesMap }: CreateProps<TEventName, TEventsContext> = {}) => {
    type ExtendedEventName = BigQueryEventName | TEventName;
    type ExtendedEventProperties = Partial<BigQueryEventsContext> & Partial<TEventsContext>;
    type ExtendedAdditionalPropsMap = TAdditionalPropsMap & AdditionalPropsMap;

    type ContextFunction = (currentContext: ExtendedEventProperties) => ExtendedEventProperties;

    let context: ExtendedEventProperties = {};
    let preservedContext: ExtendedEventProperties = {};

    let extendedMap = { ...BIGQUERY_PROPERTIES_MAP, ...bigQueryPropertiesMap };
    let extendedEmbeddedProps = { ...BIGQUERY_EMBEDDED_PROPERTIES, ...bigQueryEmbeddedProps };
    const isContextFunction = (newCtx: unknown): newCtx is ContextFunction => typeof newCtx === 'function';

    const enrichContext = (
        newContext: ExtendedEventProperties | ContextFunction,
        { resetState, preserve }: EnrichContextProps = {}
    ) => {
        const newValues = isContextFunction(newContext) ? newContext(context) : newContext;

        if (preserve) {
            preservedContext = { ...preservedContext, ...newValues };
        }

        context = {
            ...(resetState ? {} : context),
            ...newValues,
            ...preservedContext,
        };
    };

    const send = <T extends ExtendedEventName>(
        ...[type, properties]: T extends keyof ExtendedAdditionalPropsMap
            ? [name: T, properties: Partial<ExtendedAdditionalPropsMap[T]>]
            : [name: T]
    ) => {
        const typeAdditionalProps =
            type in extendedEmbeddedProps ? extendedEmbeddedProps[type as keyof typeof extendedEmbeddedProps] : {};

        bigQuery.send({
            type,
            ...mapProps({
                props: { ...typeAdditionalProps, ...context, ...properties },
                propertiesMap: extendedMap,
            }),
        });
    };

    const extend = <TNewEventName extends string, TNewEventsContext extends EventProps, TNewAdditionalPropsMap>({
        bigQueryEmbeddedProps: newEmbeddedProps,
        bigQueryPropertiesMap: newPropertiesMap,
    }: CreateProps<TNewEventName, TNewEventsContext> = {}) => {
        extendedMap = { ...extendedMap, ...newPropertiesMap };
        extendedEmbeddedProps = { ...extendedEmbeddedProps, ...newEmbeddedProps };

        return instance as unknown as ReturnType<
            typeof createBigQueryReporter<TNewEventName, TNewEventsContext, TNewAdditionalPropsMap>
        >;
    };

    const instance = {
        get enrichment() {
            return mapProps({ props: context, propertiesMap: extendedMap });
        },
        enrichContext,
        send,
        extend,
    } as const;

    return instance;
};

export const globalBigQueryReporter = createBigQueryReporter();

import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import type { Types } from '@fiverr-private/theme';
import { SkeletonSquare, Skeleton } from '@fiverr-private/feedback';
import { skeletonWrapperClasses } from './SkeletonLoading.styles.css';

interface Props {
    children?: React.ReactNode;
    loading?: boolean;
    maxHeight?: Types.ContainerProps['maxHeight'];
    height?: Types.ContainerProps['height'];
}

export const SkeletonLoading = ({ children, loading, maxHeight, height = '100%' }: Props) => (
    <Container maxHeight={maxHeight} position="relative" height={height} className={skeletonWrapperClasses}>
        {loading && (
            <Skeleton width="auto">
                <SkeletonSquare width="100%" height="100%" />
            </Skeleton>
        )}
        {children}
    </Container>
);

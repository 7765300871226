import useIsomorphicLayoutEffect from '../useIsomorphicLayoutEffect';

const HIDDEN = 'hidden';

/**
 * Allows to lock document body overflow
 * @returns `void`
 */
const useLockBodyScroll = (enabled = true) => {
    useIsomorphicLayoutEffect(() => {
        // Get original body overflow
        const originalOverflowStyle = window.getComputedStyle(document.body).overflow;

        if (enabled && originalOverflowStyle !== HIDDEN) {
            // Prevent scrolling on mount
            document.body.style.overflow = HIDDEN;

            // Re-enable scrolling when component unmounts
            return () => {
                document.body.style.overflow = originalOverflowStyle;
            };
        }
        return () => undefined;
    }, [enabled]);
};

export default useLockBodyScroll;

import React from 'react';
import { Box } from '@fiverr-private/theme';
import { DELAY, DURATION } from './constants';
import type { WipeProps } from './types';
import { wipeAnimation } from './styles.ve.css';

const WIPE_COLOR = 'rgba(255, 255, 255, 0.3)';

const Wipe = ({ animationDuration = 'moderate', animationDelay = 'moderate', dataTestId }: WipeProps) => (
  <Box
    className={wipeAnimation}
    style={{
      animationDelay: DELAY[animationDelay],
      animationDuration: DURATION[animationDuration],
    }}
    position="absolute"
    top={0}
    width="100%"
    height="100%"
    margin="autoY"
    background={`linear-gradient(85deg, transparent 25%, ${WIPE_COLOR} 50%, ${WIPE_COLOR} 55%, transparent 75%)`}
    data-testid={dataTestId}
  />
);

Wipe.displayName = 'Wipe';

export default Wipe;

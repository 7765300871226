import { mixpanel } from '@fiverr-private/obs';
import { AdditionalPropsMap, MixpanelEventName, MixpanelEventsContext } from './mixpanel.types';
import { MIXPANEL_PROPERTIES_MAP } from './mixpanel.constants';

const mapProps = <TEventProperties>({
    props,
    propertiesMap,
}: {
    props: Partial<TEventProperties>;
    propertiesMap: Record<keyof TEventProperties, string>;
}) =>
    Object.fromEntries(
        Object.entries(props)
            .filter(([_, value]) => value)
            .map(([key, value]) => {
                const formattedKey = propertiesMap[key as keyof typeof propertiesMap] || key;
                return [formattedKey, value];
            })
    );

export type MixpanelReporter = ReturnType<typeof createMixpanelReporter>;

export interface EventProps {}

export interface CreateProps<T extends string | number | symbol> {
    mixPanelPropertiesMap?: Record<T, string>;
}

export interface EnrichContextProps {
    resetState?: boolean;
    preserve?: boolean;
}

export const createMixpanelReporter = <
    TEventName extends string = MixpanelEventName,
    TEventsContext extends EventProps = MixpanelEventsContext,
    TAdditionalPropsMap = AdditionalPropsMap
>({ mixPanelPropertiesMap }: CreateProps<keyof TEventsContext> = {}) => {
    type ExtendedEventName = MixpanelEventName | TEventName;
    type ExtendedEventProperties = Partial<MixpanelEventsContext> & Partial<TEventsContext>;
    type ExtendedAdditionalPropsMap = TAdditionalPropsMap & AdditionalPropsMap;

    type ContextFunction = (currentContext: ExtendedEventProperties) => ExtendedEventProperties;

    let context: ExtendedEventProperties = {};
    let preservedContext: ExtendedEventProperties = {};

    let extendedMap = { ...MIXPANEL_PROPERTIES_MAP, ...mixPanelPropertiesMap };

    const isContextFunction = (newCtx: unknown): newCtx is ContextFunction => typeof newCtx === 'function';

    const enrichContext = (
        newContext: ExtendedEventProperties | ContextFunction,
        { resetState, preserve }: EnrichContextProps = {}
    ) => {
        const newValues = isContextFunction(newContext) ? newContext(context) : newContext;

        if (preserve) {
            preservedContext = { ...preservedContext, ...newValues };
        }

        context = {
            ...(resetState ? {} : context),
            ...newValues,
            ...preservedContext,
        };
    };

    const send = <T extends ExtendedEventName>(
        ...[name, properties]: T extends keyof ExtendedAdditionalPropsMap
            ? [name: T, properties: ExtendedAdditionalPropsMap[T]]
            : [name: T]
    ) => {
        mixpanel.track(name, mapProps({ props: { ...context, ...properties }, propertiesMap: extendedMap }));
    };

    const extend = <TNewEventName extends string, TNewEventsContext extends EventProps, TNewAdditionalPropsMap>({
        mixPanelPropertiesMap: newPropertiesMap,
    }: CreateProps<keyof TNewEventsContext> = {}) => {
        extendedMap = { ...extendedMap, ...newPropertiesMap };

        return instance as unknown as ReturnType<
            typeof createMixpanelReporter<TNewEventName, TNewEventsContext, TNewAdditionalPropsMap>
        >;
    };

    const instance = {
        get enrichment() {
            return mapProps({ props: context, propertiesMap: extendedMap });
        },
        enrichContext,
        send,
        extend,
    } as const;

    return instance;
};

export const globalMixpanelReporter = createMixpanelReporter();

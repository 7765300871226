import { useState, useEffect, useCallback, useRef } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useIsomorphicLayoutEffect } from '@fiverr-private/hooks';
import { type ModalOptions } from '../../../../shared/constants/modalOptions';
import { type BiEnrichmentParams } from '../../../../shared/constants/biEnrichmentParams';
import { registerTrigger } from '../../../../triggers/handlers';
import {
    allocateToIdentificationTest,
    allocateToIdentificationBusinessTest,
} from '../../../../utils/allocateToIdentificationTest';
import { globalMixpanelReporter } from '../../../../events/mixpanel/mixpanel.reporter';
import { globalGrafanaReporter } from '../../../../events/grafana/grafana.reporter';
import { globalBigQueryReporter } from '../../../../events/bigQuery/bigQuery.reporter';
import { handleAutoShowSignUp, setAutoShown } from '../../../../services/legacyPageViewTracker';
import { QUERY_PARAMS } from './useSetupModal.constants';

const hasAutoShowQueryParam = () => {
    const { userId, queryParameters } = getContext();
    return Boolean(!userId && queryParameters?.[QUERY_PARAMS.SHOW_JOIN]);
};

interface UseSetupModalProps {
    inLynxesIdentificationMarketplaceRollout?: boolean;
    inDolphinsIdentificationBusinessRollout?: boolean;
    autoShowSignUpEnabled?: boolean;
}

export const useSetupModal = ({
    inLynxesIdentificationMarketplaceRollout = false,
    inDolphinsIdentificationBusinessRollout = false,
    autoShowSignUpEnabled,
}: UseSetupModalProps) => {
    const isAllocatedIdentificationMarketplace = useRef(false);
    const isAllocatedIdentificationBusiness = useRef(false);
    const [autoShow, setAutoShow] = useState(hasAutoShowQueryParam);

    const [showLazyComponent, setShowLazyComponent] = useState(autoShow);
    const [isModalOpen, setIsModalOpen] = useState(autoShow);

    const [modalOptions, setModalOptions] = useState<ModalOptions>({});
    const [biEnrichmentParams, setBiEnrichmentParams] = useState<BiEnrichmentParams>({});

    useIsomorphicLayoutEffect(() => {
        if (!isModalOpen || isAllocatedIdentificationMarketplace.current) {
            return;
        }

        if (inLynxesIdentificationMarketplaceRollout) {
            allocateToIdentificationTest('Standard');
            isAllocatedIdentificationMarketplace.current = true;
        }
    }, [isModalOpen, inLynxesIdentificationMarketplaceRollout]);

    useIsomorphicLayoutEffect(() => {
        if (!isModalOpen || isAllocatedIdentificationBusiness.current) {
            return;
        }

        if (inDolphinsIdentificationBusinessRollout) {
            allocateToIdentificationBusinessTest('Standard');
            isAllocatedIdentificationBusiness.current = true;
        }
    }, [isModalOpen, inDolphinsIdentificationBusinessRollout]);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = () => {
        if (!modalOptions.isSticky) {
            globalMixpanelReporter.send('Identification - Close');
            globalBigQueryReporter.send('identification.close');
            globalGrafanaReporter.count('identification.%{platform}.close');
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        const setupIdentificationModal = (
            modalOptions: ModalOptions = {},
            biEnrichmentParams: BiEnrichmentParams = {}
        ) => {
            setShowLazyComponent(true);
            openModal();
            setAutoShow(false);

            setModalOptions(modalOptions);
            setBiEnrichmentParams(biEnrichmentParams);
        };

        registerTrigger(setupIdentificationModal);

        const shouldAutoShowSignUp = handleAutoShowSignUp({ autoShowSignUpEnabled });

        if (shouldAutoShowSignUp) {
            setAutoShown();
            setShowLazyComponent(true);
            openModal();
            setAutoShow(true);
            setModalOptions((prev) => ({ ...prev, mode: 'sign_up' }));
        }
    }, [openModal, autoShowSignUpEnabled]);

    return {
        showLazyComponent,
        modalOptions,
        biEnrichmentParams,
        openModal,
        closeModal,
        isModalOpen,
        autoShow,
    };
};

import React, { lazy } from 'react';
import { IdentificationModalWrapper } from '@fiverr-private/identification_core';

const LazyIdentificationForm = lazy(
    () => import(/* webpackChunkName: "LazyIdentificationForm" */ '../../components/IdentificationForm')
);

interface IdentificationModalProps {
    googleClientId: string;
    inLynxesIdentificationMarketplaceRollout?: boolean;
    autoShowSignUpEnabled?: boolean;
}

export const IdentificationModal = ({
    googleClientId,
    inLynxesIdentificationMarketplaceRollout,
    autoShowSignUpEnabled,
}: IdentificationModalProps) => (
    <IdentificationModalWrapper
        googleClientId={googleClientId}
        inLynxesIdentificationMarketplaceRollout={inLynxesIdentificationMarketplaceRollout}
        autoShowSignUpEnabled={autoShowSignUpEnabled}
        renderLazyComponent={({
            isModal,
            isModalOpen,
            closeModal,
            mode,
            variant,
            redirectUrl,
            email,
            username,
            isSticky,
            skipQuestionnaire,
            blockedActionType,
            bigQueryEnrichment,
            mixpanelEnrichment,
            afterSigningSuccess,
            isSocialFlow,
            stepId,
            skipActivation,
        }) => (
            <LazyIdentificationForm
                isModal={isModal}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                mode={mode}
                variant={variant}
                redirectUrl={redirectUrl}
                email={email}
                username={username}
                isSticky={isSticky}
                skipQuestionnaire={skipQuestionnaire}
                blockedActionType={blockedActionType}
                mixpanelEnrichment={mixpanelEnrichment}
                bigQueryEnrichment={bigQueryEnrichment}
                afterSigningSuccess={afterSigningSuccess}
                isSocialFlow={isSocialFlow}
                stepId={stepId}
                skipActivation={skipActivation}
            />
        )}
    />
);

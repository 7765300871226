import React, { forwardRef, Fragment } from 'react';
import classNames from 'classnames';
import { Fade, SlideFade } from '@fiverr-private/transition';
import { Portal } from '@fiverr-private/ui_utils';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useIsomorphicLayoutEffect } from '@fiverr-private/hooks';
import { useExitOnEscape } from '../../../hooks/useExitOnEscape';
import Overlay from '../../Overlay';
import { useModalContext } from '../ModalContext';
import { ModalContentProps } from '../types';
import { DIALOG_WIDTH } from './constants';

import { articleFullWidthMd, articleFullWidthSm } from './styles.ve.css';

const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  (
    {
      children,
      closeOnOverlayClick = true,
      initialFocusRef,
      renderWhenClosed = false,
      size = 'md',
      closeOnEscapeKey = true,
      overlayTestId,
      disablePortal,
      onAnimationStart,
      onAnimationEnd,
    }: ModalContentProps,
    ref
  ) => {
    const contentRef = React.useRef<HTMLDivElement>(null);
    const { close, isOpen, fullScreenBreakpoint, isDark } = useModalContext();

    useIsomorphicLayoutEffect(() => {
      if (contentRef.current && isOpen) {
        if (initialFocusRef?.current) {
          initialFocusRef.current.focus();

          return;
        }

        contentRef.current.focus();
      }
    }, [initialFocusRef, isOpen, contentRef]);

    useExitOnEscape({
      enabled: closeOnEscapeKey,
      close,
      isOpen,
    });

    const Wrapper = disablePortal ? Fragment : Portal;
    const wrapperProps = disablePortal ? undefined : { height: 0 };

    return (
      <Wrapper {...wrapperProps}>
        <Container zIndex="overlay" position="relative" ref={ref}>
          <Fade delay={{ enter: 'fast1', exit: 'moderate1' }} in={isOpen} unmountOnExit={!renderWhenClosed}>
            <Overlay
              zIndex="-1"
              lockBodyScroll={isOpen}
              onClick={closeOnOverlayClick ? close : undefined}
              dataTestId={overlayTestId}
            />
          </Fade>
          <SlideFade
            delay="fast3"
            in={isOpen}
            unmountOnExit={!renderWhenClosed}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={onAnimationEnd}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              position="fixed"
              top={0}
              bottom={0}
              left={0}
              right={0}
              height="100%"
              width="100%"
              pointerEvents="none"
              gap="0"
            >
              <Stack
                as="article"
                direction="column"
                gap="0"
                backgroundColor={isDark ? 'black' : 'white'}
                overflow="hidden"
                maxHeight="90vh"
                maxWidth={{ default: '90vw', md: '900px' }}
                borderRadius={isDark ? '2xl' : 'xl'}
                width={DIALOG_WIDTH[size]}
                aria-modal
                role="dialog"
                style={{ pointerEvents: 'auto' }}
                className={classNames(
                  { [articleFullWidthSm]: fullScreenBreakpoint === 'sm' },
                  { [articleFullWidthMd]: fullScreenBreakpoint === 'md' }
                )}
              >
                <Stack
                  ref={contentRef}
                  tabIndex={-1}
                  height="100%"
                  minHeight={0}
                  position="relative"
                  direction="column"
                  flex="1"
                  gap="0"
                >
                  {children}
                </Stack>
              </Stack>
            </Stack>
          </SlideFade>
        </Container>
      </Wrapper>
    );
  }
);

ModalContent.displayName = 'ModalContent';

export default ModalContent;

import React from 'react';
import { useComputedFullScreenBreakpoint } from '../../hooks/useComputedFullScreenBreakpoint';
import { ModalContext } from './ModalContext';
import { ModalProps } from './types';

const Modal = ({
  autoOpen = false,
  children,
  centered = false,
  isOpen,
  onClose = () => undefined,
  onOpen = () => undefined,
  fullScreenBreakpoint,
  mobileFullScreen,
  goExperimental = false,
  theme = 'light',
}: ModalProps) => {
  const isDark = goExperimental || theme === 'dark';
  const [innerIsOpen, setInnerIsOpen] = React.useState(autoOpen);
  const computedFullScreenBreakpoint = useComputedFullScreenBreakpoint(fullScreenBreakpoint, mobileFullScreen);

  const close = React.useCallback(() => {
    setInnerIsOpen(false);
    onClose();
  }, [onClose]);

  const open = React.useCallback(() => {
    setInnerIsOpen(true);
    onOpen();
  }, [onOpen]);

  if (autoOpen && isOpen) {
    throw new Error(
      "Modal: Don't use both autoOpen and isOpen together, autoOpen is only meant to be used with the trigger component"
    );
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen: isOpen ?? innerIsOpen,
        close,
        centered,
        open,
        fullScreenBreakpoint: computedFullScreenBreakpoint,
        isDark,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

Modal.displayName = 'Modal';

export default Modal;

import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { SkeletonSquareProps } from '../types';

const SkeletonSquare = ({
  width = '100%',
  height = '16px',
  maxHeight,
  maxWidth = '100%',
  dataTestId,
  borderRadius = 'sm',
}: SkeletonSquareProps) => (
  <Container
    backgroundColor="grey_300"
    borderRadius={borderRadius}
    width={width}
    height={height}
    maxHeight={maxHeight}
    maxWidth={maxWidth}
    data-testid={dataTestId}
  />
);
SkeletonSquare.displayName = 'SkeletonSquare';

export default SkeletonSquare;

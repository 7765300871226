import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Wipe } from '@fiverr-private/transition';
import { SkeletonProps } from './types';

const Skeleton = ({
  children,
  animationDuration = 'moderate',
  animationDelay = 'moderate',
  width = 'fit-content',
  dataTestId,
}: SkeletonProps) => (
  <Container width={width} overflow="hidden" position="relative" data-testid={dataTestId}>
    <Wipe animationDuration={animationDuration} animationDelay={animationDelay} />
    {children}
  </Container>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;

import { BigQueryEventName, BigQueryEventProperties } from './bigQuery.types';

export const BIGQUERY_PROPERTIES_MAP: Record<keyof BigQueryEventProperties, string> = {
    mode: 'page.element.type',
    provider: 'user.social_identity',
    group: 'group',
    triggerId: 'page.element.id',
    triggerMetadata: 'page.element.metadata',
    step: 'identification.step',
    experience: 'experience',
    redirectUrl: 'redirectUrl',
    funnel: 'feature.name',
    reason: 'reason',
    flow: 'signingFlowType',
    emailDomain: 'emailDomain',
    pageElementName: 'page.element.name',
};

const IDENTIFICATION_EMBEDDED_PROPERTIES: Partial<BigQueryEventProperties> = { group: 'identification flow' };
const SOCIAL_FLOW_EMBEDDED_PROPERTIES: Partial<BigQueryEventProperties> = { group: 'social flow' };
const GOOGLE_ONE_TAP_EMBEDDED_PROPERTIES: Partial<BigQueryEventProperties> = {
    group: 'social flow',
    pageElementName: 'google_one_tap',
};

export const BIGQUERY_EMBEDDED_PROPERTIES: Partial<Record<BigQueryEventName, Partial<BigQueryEventProperties>>> = {
    'identification.close': IDENTIFICATION_EMBEDDED_PROPERTIES,
    'identification.back': IDENTIFICATION_EMBEDDED_PROPERTIES,
    'identification.completed': IDENTIFICATION_EMBEDDED_PROPERTIES,
    google_click: SOCIAL_FLOW_EMBEDDED_PROPERTIES,
    facebook_click: SOCIAL_FLOW_EMBEDDED_PROPERTIES,
    apple_click: SOCIAL_FLOW_EMBEDDED_PROPERTIES,
    authentication_completed: SOCIAL_FLOW_EMBEDDED_PROPERTIES,
    'google_one_tap.show': GOOGLE_ONE_TAP_EMBEDDED_PROPERTIES,
    'google_one_tap.submit': GOOGLE_ONE_TAP_EMBEDDED_PROPERTIES,
    'google_one_tap.close': GOOGLE_ONE_TAP_EMBEDDED_PROPERTIES,
};
